import user from "~/assets/icons/user.svg";
import support from "~/assets/icons/support.svg";
import email from "~/assets/icons/email.svg";
import store from "~/assets/icons/store.svg";
import magnifyingGlass from "~/assets/icons/magnifyingGlass.svg";
import chat from "~/assets/icons/chat.svg";
import calendar from "~/assets/icons/calendar.svg";
import heart from "~/assets/icons/heart.svg";
import shoppingBag from "~/assets/icons/shoppingBag.svg";
import stars from "~/assets/icons/stars.svg";
import romaLogo from "~/assets/icons/romaLogo.svg";
import lightning from "~/assets/icons/lightning.svg";
import drafting from "~/assets/icons/drafting.svg";
import pictureFrame from "~/assets/icons/pictureFrame.svg";
import computerScreen from "~/assets/icons/computerScreen.svg";
import pallette from "~/assets/icons/pallette.svg";
import shoppingCart from "~/assets/icons/shoppingCart.svg";
import parcel from "~/assets/icons/parcel.svg";
import heartrate from "~/assets/icons/heartrate.svg";
import star from "~/assets/icons/star.svg";
import desklamp from "~/assets/icons/desklamp.svg";
import bookshelf from "~/assets/icons/bookshelf.svg";
import globeHeart from "~/assets/icons/globeHeart.svg";
import flag from "~/assets/icons/flag.svg";
import eye from "~/assets/icons/eye.svg";
import diamond from "~/assets/icons/diamond.svg";
import stopwatch from "~/assets/icons/stopwatch.svg";
import book from "~/assets/icons/book.svg";
import leadership from "~/assets/icons/leadership.svg";
import speedGauge from "~/assets/icons/speedGauge.svg";
import balloons from "~/assets/icons/balloons.svg";
import rocketship from "~/assets/icons/rocketship.svg";
import chainlink from "~/assets/icons/chainlink.svg";
import usersDouble from "~/assets/icons/usersDouble.svg";
import chair from "~/assets/icons/chair.svg";
import praiseHands from "~/assets/icons/praiseHands.svg";
import romaStars from "~/assets/icons/romaStars.svg";
import masonry from "~/assets/icons/masonry.svg";
import plantHand from "~/assets/icons/plantHand.svg";
import heartHand from "~/assets/icons/heartHand.svg";
import headset from "~/assets/icons/headset.svg";
import tour1star from "~/assets/icons/tour1star.svg";
import tour2star from "~/assets/icons/tour2star.svg";
import tour3star from "~/assets/icons/tour3star.svg";
import ordering from "~/assets/icons/ordering.svg";
import shipping from "~/assets/icons/shipping.svg";
import returns from "~/assets/icons/returns.svg";
import billing from "~/assets/icons/billing.svg";
import catalogue from "~/assets/icons/catalogue.svg";

import us from "~/assets/icons/flags/us.svg";
import ca from "~/assets/icons/flags/ca.svg";
import it from "~/assets/icons/flags/it.svg";
import fr from "~/assets/icons/flags/fr.svg";

import flat from "~/assets/profiles/flat.svg";
import curved from "~/assets/profiles/curved.svg";
import enhancer from "~/assets/profiles/enhancer.svg";
import fillet from "~/assets/profiles/fillet.svg";
import floater from "~/assets/profiles/floater.svg";
import stretcher from "~/assets/profiles/stretcher.svg";
import swan from "~/assets/profiles/swan.svg";
import scoop from "~/assets/profiles/scoop.svg";
import slope from "~/assets/profiles/slope.svg";
import complex from "~/assets/profiles/complex.svg";

export const flags: Record<string, string> = {
  us: us,
  ca: ca,
  it: it,
  fr: fr,
};

export const iconPath: Record<string, string> = {
  User: user,
  "Users Double": usersDouble,
  Support: support,
  Email: email,
  Store: store,
  "Magnifying Glass": magnifyingGlass,
  Chat: chat,
  Calendar: calendar,
  Heart: heart,
  "Shopping Bag": shoppingBag,
  Stars: stars,
  Ordering: ordering,
  Shipping: shipping,
  Returns: returns,
  Billing: billing,
  Catalogue: catalogue,
  Chair: chair,
  "Roma Logo": romaLogo,
  Lightning: lightning,
  Drafting: drafting,
  "Picture Frame": pictureFrame,
  "Computer Screen": computerScreen,
  Pallette: pallette,
  "Shopping Cart": shoppingCart,
  Parcel: parcel,
  Heartrate: heartrate,
  Star: star,
  "Desk Lamp": desklamp,
  Bookshelf: bookshelf,
  "Globe With Heart": globeHeart,
  Flag: flag,
  Eye: eye,
  Diamond: diamond,
  Stopwatch: stopwatch,
  Book: book,
  Leadership: leadership,
  "Speed Gauge": speedGauge,
  Balloons: balloons,
  Rocketship: rocketship,
  Chainlink: chainlink,
  "Praise Hands": praiseHands,
  "Roma Stars": romaStars,
  Masonry: masonry,
  "Heart Hand": heartHand,
  "Plant Hand": plantHand,
  Headset: headset,
  "Tour 1 Star": tour1star,
  "Tour 2 Star": tour2star,
  "Tour 3 Star": tour3star,
};

export const profileIcons: Record<string, string> = {
  Flat: flat,
  Curved: curved,
  Enhancer: enhancer,
  Fillet: fillet,
  Floater: floater,
  Stretcher: stretcher,
  Swan: swan,
  Scoop: scoop,
  Slope: slope,
  Complex: complex,
};
