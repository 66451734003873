import {
  Component,
  ComponentProps,
  Show,
  JSX,
  createSignal,
  Switch,
  Match,
} from "solid-js";
import { BaseLoader } from "../utility";
import { Select } from "@kobalte/core";
import { Icon } from "solid-heroicons";
import { chevronDown, check } from "solid-heroicons/outline";

import "./selectBox.css";

type SelectOption = {
  label: string | JSX.Element;
  value: string | number;
  [key: string]: any;
};

type SelectRootProps = ComponentProps<typeof Select.Root>;
export type SelectBoxProps = Partial<
  Pick<
    SelectRootProps,
    | "name"
    | "defaultValue"
    | "optionValue"
    | "optionTextValue"
    | "optionDisabled"
    | "itemComponent"
    | "placeholder"
    | "onOpenChange"
    | "disabled"
    | "validationState"
    | "id"
    | "required"
    | "value"
    | "allowDuplicateSelectionEvents"
    | "disallowEmptySelection"
    | "selectionBehavior"
  >
> & {
  options: SelectOption[];
  hideIndicator?: boolean;
  error?: string | string[] | null | undefined;
  loading?: boolean;
  label?: string | JSX.Element;
  inlineTitle?: string;
  inlinePlaceholder?: string;
  onChange?: (obj: SelectOption) => void;
  background?: string;
  class?: string;
  labelClass?: string;
  valueClass?: string;
  itemClass?: string;
  triggerClass?: string;
  contentClass?: string;
  listboxClass?: string;
  errorClass?: string;
  globalClass?: string;
  chevronClass?: string;
  checkClass?: string;
  zIndex?: string | number;
  variantType?: "justify-between" | "custom";
  itemComp?: (labelProps: any) => JSX.Element;
  hiddenSelect?: boolean;
};

export const VariantLabel: Component<{
  left: string | JSX.Element;
  right: string | JSX.Element;
  class?: string;
}> = (props) => {
  return (
    <div
      class="w-full flex justify-between pr-4"
      classList={{ [`${props.class}`]: !!props.class }}
    >
      <span>{props.left}</span>
      <span>{props.right}</span>
    </div>
  );
};

export const SelectBox: Component<SelectBoxProps> = (props: any) => {
  const [selected, setSelected] = createSignal(
    props.defaultValue ? { value: props.defaultValue } : null
  );

  const labelClass = () =>
    props.labelClass ??
    "block text-roma-dark-grey data-[disabled]:text-gray-300 text-sm font-bold mb-2";

  const valueClass = () => {
    const base =
      "data-[placeholder-shown]:text-gray-400 text-sm text-ellipses overflow-hidden w-full text-left";
    return `${base} ${props.valueClass ?? ""}`;
  };

  const itemClass = () => {
    const base =
      "select-none outline-none cursor-pointer data-[disabled]:pointer-events-none data-[disabled]:text-gray-300 relative";
    const style =
      "rounded-md flex justify-between items-center p-2 data-[highlighted]:bg-roma-grey !w-full";
    return `${base} ${props.itemClass ?? style}`;
  };

  const triggerClass = () => {
    const base =
      "data-[expanded]:ring-1 data-[expanded]:ring-roma-blue data-[expanded]:border-roma-blue hover:border-neutral-400 disabled:cursor-not-allowed focus-roma";
    const style = `${
      props.background ?? "bg-inherit"
    } inline-flex justify-between items-center w-full px-3 py-2 text-roma-dark-grey data-[disabled]:text-gray-300 border border-gray-300 rounded-md`;
    return `${base} ${style} ${props.triggerClass}`;
  };

  const contentClass = () => {
    const base = "select__content overflow-auto outline-none";
    const style = `px-2 py-1 text-sm bg-white -t-10 shadow-md  rounded-md z-[50] w-full border`;
    return `${base} ${style} ${props.contentClass}`;
  };

  const listboxClass = () => {
    const base = "outline-none overflow-auto my-1";
    const style = "max-h-[25vh]";
    return `${base} ${props.listboxClass ?? style}`;
  };

  const errorClass = () => props.errorClass ?? "text-xs text-red-600 pt-2";

  return (
    <div classList={{ [`${props.class}`]: !!props.class }}>
      <Select.Root
        {...props}
        optionValue={props.optionValue ?? "value"}
        optionTextValue={props.optionTextValue ?? "label"}
        optionDisabled={"disabled"}
        disabled={props.disabled || props.loading}
        value={props.value ?? selected()}
        onChange={(option: SelectOption) => {
          setSelected(option);
          if (props.onChange && option) {
            props.onChange(option);
          }
        }}
        itemComponent={(compProp) => (
          <>
            <Select.Item item={compProp.item} class={itemClass()}>
              <Switch
                fallback={
                  <Select.ItemLabel class="w-full">
                    {compProp.item.rawValue?.label}
                  </Select.ItemLabel>
                }
              >
                <Match when={props.variantType === "justify-between"}>
                  <Select.ItemLabel class="w-full">
                    <VariantLabel
                      left={compProp.item.rawValue?.leftLabel}
                      right={compProp.item.rawValue?.rightLabel}
                    />
                  </Select.ItemLabel>
                </Match>
                <Match
                  when={props.variantType === "custom" && !!props.itemComp}
                >
                  <Select.ItemLabel class="w-full">
                    {props.itemComp(compProp.item.rawValue)}
                  </Select.ItemLabel>
                </Match>
              </Switch>

              <Select.ItemIndicator>
                <Icon
                  path={check}
                  class={`w-5 h-5 ${props.checkClass ? props.checkClass : ""}`}
                />
              </Select.ItemIndicator>
            </Select.Item>
          </>
        )}
      >
        <Show when={props.hiddenSelect}>
          <Select.HiddenSelect />
        </Show>
        <Show when={props.label}>
          <Select.Label class={labelClass()}>{props.label}</Select.Label>
        </Show>
        <Select.Trigger class={triggerClass()} aria-label={props.label}>
          <div class={valueClass()}>
            <Show when={props.inlineTitle}>
              <span class="font-bold mr-1.5">{props.inlineTitle}</span>
            </Show>
            <Select.Value class={valueClass()}>
              {(state: { selectedOption: () => SelectOption }) => (
                <>
                  <Show
                    when={
                      !state.selectedOption() &&
                      props.inlineTitle &&
                      props.inlinePlaceholder
                    }
                  >
                    <span class="text-roma-medium-grey">
                      {props.inlinePlaceholder}
                    </span>
                  </Show>
                  <Switch fallback={state.selectedOption()?.label}>
                    <Match when={props.variantType === "justify-between"}>
                      <VariantLabel
                        left={state.selectedOption()?.leftLabel}
                        right={state.selectedOption()?.rightLabel}
                      />
                    </Match>
                    <Match
                      when={props.variantType === "custom" && !!props.itemComp}
                    >
                      {props.itemComp(state.selectedOption())}
                    </Match>
                  </Switch>
                </>
              )}
            </Select.Value>
          </div>
          <Show
            when={!props.loading}
            fallback={<BaseLoader width={5} height={5} class="!mr-0" />}
          >
            <Show when={!props.hideIndicator}>
              <Select.Icon class="ml-1">
                <Icon
                  path={chevronDown}
                  class={`${props.chevronClass ?? "w-5 h-5"} `}
                />
              </Select.Icon>
            </Show>
          </Show>
        </Select.Trigger>
        <Select.ErrorMessage class={errorClass()}>
          {props.error}
        </Select.ErrorMessage>
        <Select.Portal>
          <Select.Content class={contentClass()}>
            <Select.Listbox class={listboxClass()} />
          </Select.Content>
        </Select.Portal>
      </Select.Root>
    </div>
  );
};
